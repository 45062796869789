<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <div class="flex-column">
                <div class="d-flex" v-if="!loading && finished">
                    <div class="flex-column padding-div">
                        <div v-if="!showFolhaDePagamento && !waitCheck">
                            <div class="fs-32px fw-700 color-dark">Sua contribuição será feita por:</div>
                            <div class="mt-5">
                                <div class="select-boxes-row">
                                    <div v-bind:class="(selection == false ? 'select-box select-box-1 active' : 'select-box select-box-1')" @click="select(false)">
                                        <p class="mb-0">Boleto bancário</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div v-else>
                            <div class="fs-28px fw-700 color-dark" v-if="fluxo=='menor18' || fluxo == 'convidadomenor18' || fluxo=='aberturamenor18'">Você gostaria que a sua contribuição fosse automática?</div>
                            <div class="fs-28px fw-700 color-dark" v-else>Quero contribuir por desconto em folha</div>
                            <div class="mt-5">
                                <div class="select-boxes-row">
                                    <div v-bind:class="(selection == true ? 'select-box select-box-1 active': 'select-box select-box-1')" @click="select(true)">
                                        <div class="valia-tooltip-no-text-decoration">
                                            <div class="select-box-muted">
                                                <p class="mb-0">Sim</p>
                                                <p class="mb-0">(autorizo desconto em folha)</p>
                                            </div>
                                            <span class="valia-tooltiptext" v-if="showFolhaDePagamento && (fluxo!='menor18' && fluxo!='convidadomenor18' && fluxo!='aberturamenor18' && fluxo!='aberturamaior18' )">
                                                Autorizo o desconto em minha folha de pagamento e estou ciente de que, ao optar pelo desconto dos aportes para o Prevaler em folha de pagamento, dispenso a Empregadora de incluir tais montantes como despesa dedutível antecipadamente na apuração mensal do Imposto de Renda, cabendo exclusivamente ao próprio participante avaliar o tratamento tributário adequado, bem como o cumprimento dos requisitos legais eventualmente aplicáveis ao apresentar a Declaração de Ajuste Anual do Imposto sobre a Renda.
                                            </span>
                                            <span class="valia-tooltiptext" v-if="showFolhaDePagamento && (fluxo=='menor18' || fluxo=='convidadomenor18' || fluxo=='aberturamenor18')">
                                                Autorizo o desconto em minha folha de pagamento para este participante e estou ciente de que, ao optar pelo desconto dos aportes para o Prevaler em folha de pagamento, dispenso a Empregadora de incluir tais montantes como despesa dedutível antecipadamente na apuração mensal do Imposto de Renda, cabendo exclusivamente ao próprio participante avaliar o tratamento tributário adequado, bem como o cumprimento dos requisitos legais eventualmente aplicáveis ao apresentar a Declaração de Ajuste Anual do Imposto sobre a Renda.
                                            </span>
                                            <span class="valia-tooltiptext" v-if="showFolhaDePagamento && (fluxo == 'aberturamaior18' && pessoa['perfil-pessoal'].codigoEmpresa != null)">
                                                A cobrança da contribuição no valor de R${{humanFormat(pessoa["perfil-plano"]["valor-contribuicao"].replace('.', '').replace(',', '.'))}} para o Plano Prevaler será encaminhada à empresa {{pessoa["perfil-pessoal"].nomeEmpresa}} para desconto em seu contracheque.
                                            </span>
                                        </div>
                                    </div>
                                    <div v-bind:class="(selection == false ? 'select-box select-box-2 active' : 'select-box select-box-2')" v-if="fluxo != 'aberturamaior18'" @click="select(false)">
                                        <p class="mb-0">Não</p>
                                        <p class="mb-0">(prefiro pagar por boleto)</p>
                                    </div>
                                    <div v-bind:class="(selection == false ? 'select-box select-box-1 active' : 'select-box select-box-1')" v-if="fluxo == 'aberturamaior18' && pessoa['perfil-pessoal'].codigoEmpresa != null" @click="select(false)">
                                        <div class="valia-tooltip-no-text-decoration">
                                            <div class="select-box-muted">
                                                <p class="mb-0">Não</p>
                                                <p class="mb-0">(prefiro pagar por boleto)</p>
                                            </div>
                                            <span class="valia-tooltiptext">
                                                A cobrança da contribuição no valor de R${{humanFormat(pessoa["perfil-plano"]["valor-contribuicao"].replace('.', '').replace(',', '.'))}} para o Plano Prevaler será realizada através de boleto.
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>
<script>
    import 'bootstrap';
    import CorporativoAPI from '../../services/CorporativoAPI';
    import { mapState } from "vuex";
    import StepTracker from "../../services/StepTracker";
    export default {
        name: 's02-descontofolha',
        props: ['pessoa', 'visible', 'fluxo', 'number'],
        data: () => ({
            options: {
                locale: "pt-BR",
                length: 11,
                precision: 2,
                empty: null,
            },
            isActive: false,
            //loading: false,
            selection: false,
            step: 1,
            descontoFolha: null,
            showFolhaDePagamento: false,
            finished: false,
            trackMsg: null,
            waitCheck: true
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        computed: {
            ...mapState(["loading"]),
            getPessoa() {
                return this.pessoa
            },
        },
        methods: {
            humanFormat: function (value) {
                if (value || value === 0) {
                    value = Number(value).toLocaleString(this.options.locale, {
                        maximumFractionDigits: this.options.precision,
                        minimumFractionDigits: this.options.precision,
                    });
                } else {
                    value = this.options.empty;
                }
                return value;
            },
            toggle() {
                this.isActive = !this.isActive;
                if (this.isActive) {
                    this.selection == (this.pessoa["perfil-plano"]["forma-cobranca"] == "F" || this.pessoa["perfil-plano"]["forma-cobranca"] == "f")
                    this.habilitaOpcoes();
                }
                this.trackMsg = null;
            },
            select(val) {
                if (val)
                    this.pessoa["perfil-plano"]["forma-cobranca"] = "f";
                else {
                    this.pessoa["perfil-plano"]["forma-cobranca"] = "b";
                }
                this.next();
            },
            async habilitaOpcoes() {
                // var vm = this;
                var bypassChecks = false;
                this.showFolhaDePagamento = false;
                var formaCobrancaInicial = this.pessoa["perfil-plano"]["forma-cobranca"];
                if (formaCobrancaInicial === undefined || formaCobrancaInicial == "" || formaCobrancaInicial == "B" || formaCobrancaInicial == "b") {
                    this.pessoa["perfil-plano"]["forma-cobranca"] = "b";
                    this.selection = false;
                }
                else if (formaCobrancaInicial == "F" || formaCobrancaInicial == "f") {
                    this.showFolhaDePagamento = true;
                    this.selection = true;
                    this.finished = true;
                    //return;
                }
                var tipoPublico = this.pessoa["participante-valia"]["tipo-publico"];
                if (tipoPublico != null) {
                    var ativo = (tipoPublico == "ATIVOS DESCONTO EM FOLHA");
                    var aposentado = (tipoPublico == "aposentado" || tipoPublico == "APOSENTADO" || tipoPublico == "aposentado programado" || tipoPublico == "APOSENTADO PROGRAMADO");
                    var pensionista = (tipoPublico == "pensionista" || tipoPublico == "PENSIONISTA");
                }
                //bypass via abertura
                if (this.fluxo == "aberturamaior18" && this.pessoa["perfil-pessoal"].codigoEmpresa != null) {
                    this.showFolhaDePagamento = true;
                    this.finished = true;
                    bypassChecks = true;
                }
                if (!bypassChecks) {
                    this.waitCheck = true;
                    if (ativo) {
                        console.log("ativo");
                        //EventBus.$emit("disableInterceptor");
                        //
                        await CorporativoAPI.getEmpresasDescontoFolha(this.pessoa["participante-valia"].empresa)
                            .then(response => {
                                this.descontoFolha = response.data;
                                this.showFolhaDePagamento = this.descontoFolha.habilitada && this.descontoFolha.encontrada;
                                this.finished = true;
                                this.waitCheck = false;
                                // EventBus.$emit("enableInterceptor");
                            })
                            .catch(e => {
                                console.log(e);
                                // EventBus.$emit("enableInterceptor");
                            });
                    }
                    else if (aposentado || pensionista) {
                        this.showFolhaDePagamento = true;
                        this.finished = true;
                        this.waitCheck = false;
                    }
                    else {
                        //boleto?
                        this.finished = true;
                        this.waitCheck = false;
                    }
                }
                //se pode ser folha pagamento, seta como default caso nao tenha sido escolhido previamente
                if ((this.showFolhaDePagamento) && (StepTracker.returnStep(this.number) == null)) {
                    this.pessoa["perfil-plano"]["forma-cobranca"] = "f";
                    this.selection = true;
                }
            },
            next: function () {

                if (this.fluxo == 'aberturamenor18' || this.fluxo == 'aberturamaior18') {
                    if ((this.pessoa["perfil-plano"]["forma-cobranca"] == "F" || this.pessoa["perfil-plano"]["forma-cobranca"] == "f")) {
                        this.pessoa["perfil-plano"]["tipo-folha-pagamento"] = "1";
                    }
                }

                StepTracker.registerStep(this.number, this.trackMsg);
                this.$emit('next');
            },
            back: function () {
                this.$emit('back');
            }
        },
    };
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

