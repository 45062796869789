<template>
    <transition name="slide-up">
        <div class="content-container content-half" v-if="isActive">
            <v-form ref="form">
                <div class="flex-column">
                    <div class="d-flex">
                        <!--<div class="question-number">7-</div>-->
                        <div class="flex-column padding-div w-100">
                            <div class="fs-28px fw-700 color-dark line-height-42" v-if="fluxo=='proprio' || fluxo=='propriodpub' || fluxo=='maior18' || fluxo=='convidadomaior18'|| fluxo=='aberturamaior18'">Informe a faixa de rendimento mensal em reais</div>
                            <div class="fs-28px fw-700 color-dark line-height-42" v-if="fluxo=='menor18' || fluxo=='convidadomenor18'|| fluxo=='aberturamenor18'">Qual é a sua renda familiar total?</div>
                            <span class="fs-26px color-gray line-height-42" v-if="fluxo=='proprio' || fluxo=='propriodpub' || fluxo=='maior18' || fluxo=='convidadomaior18'|| fluxo=='aberturamaior18'">Considere o rendimento familiar, caso não possua renda própria.</span>
                            <span class="fs-26px color-gray line-height-42" v-if="fluxo=='menor18' || fluxo=='convidadomenor18'|| fluxo=='aberturamenor18' ">Caso o menor tenha renda própria, some aos seus rendimentos.</span>
                            <div class="n-form-select">
                                <div class="single-input">
                                    <v-select placeholder="Selecione a faixa de rendimento mensal" class="input-caps" required :rules="[rules.required]" v-bind:items="itemsFaixaSalarial" v-model="faixaSalarial" item-value="incidencia" item-text="descricao"></v-select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="content-buttons">
                    <div class="d-flex justify-content-end">
                        <button v-on:click="next" class="button-primary-bg-primary action-button txt-uppercase">
                            Avançar
                            <v-icon right>east</v-icon>
                        </button>
                    </div>

                </div>
            </v-form>
        </div>
    </transition>
</template>

<script>
    import 'bootstrap';

    import CorporativoAPI from '../../services/CorporativoAPI';
    //import { mapState } from "vuex";
    export default {
        name: 's07-rendimento-mensal',
        props: ['pessoa', 'visible', 'fluxo'],
        data: () => ({
            isActive: false,
            loading: false,
            selection: false,
            step: 1,
            faixaSalarial: null,
            itemsFaixaSalarial: [],
            hasErrors: false,
            rules: {
            },
        }),
        watch: {
            visible: function () {
                this.toggle();
            }
        },
        methods: {
            toggle() {
                this.isActive = !this.isActive;

                if (this.isActive) {
                       this.habilitaOpcoes();
                }
            },
            habilitaOpcoes() {
                if (this.pessoa !== null && this.pessoa["perfil-plano"] != null && this.pessoa["perfil-plano"]["faixa-salarial"] != null) {
                    var v = this.pessoa["perfil-plano"]["faixa-salarial"]
                    this.faixaSalarial = v;
                }

                CorporativoAPI.getFaixaSalarialAll()
                    .then(response => {
                        this.itemsFaixaSalarial = response.data.resultado;
                    })
                    .catch(e => {
                        console.log(e);
                    });
            },
            next: function (event) {
                event.preventDefault();

                this.rules = {
                    required: value => !!value || 'Campo obrigatório.',
                };

                this.$nextTick(() => {
                    if (this.$refs.form.validate()) {
                        var v = this.faixaSalarial;
                        this.pessoa["perfil-plano"]["faixa-salarial"] = v;
                        this.$emit('next');
                    }
                });
            },

            back: function () {
                this.$emit('back');
            }
        },
    };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

